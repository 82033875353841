<template>
  <div>
    <dx-modal :title="`Solicitação de exames ${type === 'exam' ? 'clínicos' : 'laboratoriais'}`"
              :value="show" @input="close"
              id="modal-exam" size="lg">

      <div class="loading loading-lg" v-if="loading" />

      <template v-else>
        <div class="group-title">
          <div class="columns form-group">
            <div class="column col-6">
              <div class="input-group" :class="{loading: loadingModels}">
                <select class="form-select" v-model="modelId"
                        :class="{loading: loadingModels}"
                        @change="getModel">
                  <option value="">Meus protocolos de exames</option>
                  <option value="mostRequested">Mais solicitados</option>
                  <option v-for="(item, i) in models" :value="item.id" :key="i">
                    {{ item.name }}
                  </option>
                </select>
                <button class="btn btn-neutral input-group-btn"
                        @click="openEditModel">
                  Gerenciar
                </button>
              </div>
            </div>
          </div>
        </div>

        <template v-if="form.items.length === 0">
          <div class="empty">
            <div class="empty-icon">
              <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
            </div>
            <p class="empty-title h6">Lista de exames</p>
            <p class="empty-subtitle">
              Nenhum exame adicionado. Utilize o campo abaixo para adicionar.
            </p>
          </div>
        </template>

        <template v-else>
          <table class="table">
            <thead>
            <tr>
              <th style="width: 50px">#</th>
              <th>Código</th>
              <th>Descrição</th>
              <th class="text-center" style="width: 20px">Quantidade</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in form.items" :key="i">
              <td>{{ i + 1 }}</td>
              <td style="width: 100px">{{ item.item.code | tuss }}</td>
              <td>{{ item.item.name }}</td>
              <td class="text-center">
                <input type="text" class="form-input input-sm text-center"
                       v-mask-number maxlength="2"
                       v-model="item.quantity">
              </td>
              <td class="text-right" style="width: 40px">
                <button class="btn btn-sm btn-action btn-icon btn-error"
                        @click="removeExpense(i)">
                  <fa-icon :icon="['fal', 'times']"></fa-icon>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="columns mt-2">
            <div class="column col-6">
          <textarea class="form-input" rows="2"
                    placeholder="Indicação clínica"
                    autocomplete="nope"
                    v-model="form.indication" />
            </div>
            <div class="column col-6">
          <textarea class="form-input" rows="2"
                    placeholder="Observação / Justificativa"
                    autocomplete="nope"
                    v-model="form.justification" />
            </div>
          </div>
        </template>

        <div class="search-filter">
          <div class="divider" />
          <span>Informe o nome ou código do exame</span>
          <dx-autocomplete
            id="expense"
            ref="expense"
            v-model="expense"
            :source="findExpense"
            label="expense"
            track-by="expense-id"
            @select="setExpense"
            :debounce="800"
            placeholder="Pesquisar exame por código ou descrição">
            <button slot="action"
                    class="btn btn-neutral btn-action input-group-btn btn-icon">
              <fa-icon :icon="['fal', 'search']"/>
            </button>
            <template v-slot="{ item }">
              <span>
                <fa-icon class="text-primary" :icon="['fal', 'file-medical-alt']"></fa-icon>
                {{ item.name }} ({{ item.code | tuss }})
              </span>
              <span>{{ item.display }}</span>
            </template>
          </dx-autocomplete>
        </div>
      </template>
      <template slot="footer">
        <button class="btn btn-primary mr-1"
                v-if="form.items.length > 0"
                :disabled="saving || loading" @click="save"
                :class="{loading: saving}">Salvar
        </button>
        <button class="btn" @click="close">Sair</button>
      </template>
    </dx-modal>

    <dx-modal title="Exames mais solicitados"
              :value="modalMostRequested.show" size="lg"
              @input="modalMostRequested.show = false"
              id="modal-most-requested">
      <template v-if="modalMostRequested.items.length === 0">
        <div class="empty mt-2">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
          </div>
          <p class="empty-title h5">Nenhum exame solicitado</p>
          <p class="empty-subtitle">
            Ainda não foi realizado nenhuma solicitação de exames.
          </p>
        </div>
      </template>
      <table class="table table-striped table-hover" v-else>
        <thead>
        <tr>
          <th></th>
          <th>Código</th>
          <th>Descrição</th>
          <th class="text-center">Quantidade</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in modalMostRequested.items" :key="i">
          <td class="text-center" width="60px">
            <div class="form-group">
              <label class="form-checkbox">
                <input type="checkbox" v-model="item.selected">
                <i class="form-icon"></i>
              </label>
            </div>
          </td>
          <td>{{ item.code | tuss }}</td>
          <td>{{ item.name }}</td>
          <td class="text-center">{{ item.quantity }}</td>
        </tr>
        </tbody>
      </table>
      <template slot="footer">
        <button class="btn btn-gray mr-1"
                :class="{loading: modalMostRequested.saving}"
                :disabled="modalMostRequested.saving
                  || modalMostRequested.items.length === 0"
                @click="addMostRequested">Adicionar</button>
        <button class="btn" @click="modalMostRequested.show = false">Sair</button>
      </template>
    </dx-modal>

    <modal-edit
      v-if="editModels.show"
      :show="editModels.show"
      @close="closeEditModel"
    />
  </div>
</template>

<script>
import { randomHash } from '@/helpers';
import { mapState } from 'vuex';
import ModalEdit from '../../../../template/components/request/modals/Main.vue';
import { mergeFrom } from '../../../../../helpers/object';

export default {
  components: {
    ModalEdit,
  },
  props: {
    show: {
      type: Boolean,
    },
    medicalRecordId: {
      type: String,
    },
    bundleId: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      models: [],
      modelId: '',
      expense: '',
      loading: false,
      saving: false,
      loadingModels: false,
      modalMostRequested: {
        show: false,
        saving: false,
        items: [],
      },
      editModels: {
        show: false,
      },
      form: this.blankForm(),
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  },
  async mounted() {
    this.loadModels();
    this.loadMostRequested();
    if (this.bundleId) {
      this.load();
    }
  },
  methods: {
    load() {
      this.loading = true;
      this.$http.get(`/medical-records/${this.medicalRecordId}/prescriptions/${this.bundleId}`)
        .then(({ data }) => {
          this.form = mergeFrom(this.blankForm(), data);
        })
        .catch(this.$toast.error)
        .finally(() => {
          this.loading = false;
        });
    },
    loadModels() {
      const params = {
        limit: 0,
        type: this.type,
        professionalId: this.user.id,
      };
      this.$http.get('/request-templates', { params })
        .then(({ data }) => {
          this.models = data.items;
        })
        .catch(this.$toast.error);
    },
    getModel() {
      if (!this.modelId) {
        return;
      }

      if (this.modelId === 'mostRequested') {
        this.modalMostRequested.items.forEach((item) => {
          item.selected = false;
        });
        this.modalMostRequested.show = true;
      } else {
        this.loadingModels = true;
        this.$http.get(`/request-templates/${this.modelId}`)
          .then(({ data }) => {
            if (data && data.expenses.length > 0) {
              data.expenses.forEach((expense) => {
                const found = this.form.items.find(item => item.id === expense.id);
                if (found) {
                  const result = Number(found.quantity);
                  found.quantity = result + Number(expense.quantity);
                } else {
                  this.form.items.push({
                    id: '',
                    key: randomHash(),
                    item: {
                      id: expense.id,
                      code: expense.code,
                      name: expense.name,
                    },
                    quantity: expense.quantity,
                  });
                }
              });
            }
          })
          .catch(this.$toast.error)
          .then(() => {
            this.modelId = '';
            this.loadingModels = false;
          });
      }
    },
    openEditModel() {
      this.editModels.show = true;
    },
    closeEditModel() {
      this.loadModels();
      this.editModels.show = false;
    },
    addMostRequested() {
      const selectedItems = this.modalMostRequested.items
        .filter(({ selected }) => selected);
      if (selectedItems) {
        selectedItems.forEach((expense) => {
          const found = this.form.items.find(item => item.id === expense.id);
          if (found) {
            const result = Number(found.quantity);
            found.quantity = result + Number(expense.quantity);
          } else {
            this.form.items.push({
              id: '',
              key: randomHash(),
              item: {
                id: expense.id,
                code: expense.code,
                name: expense.name,
              },
              quantity: expense.quantity,
            });
          }
        });
      }
      this.modalMostRequested.show = false;
      this.modelId = '';
    },
    save() {
      const data = {
        items: this.form.items.map(item => ({
          type: this.type,
          item: item.item,
          quantity: item.quantity || 1,
          justification: this.form.justification,
          indication: this.form.indication,
        })),
      };

      const request = this.bundleId
        ? this.$http
          .put(`/medical-records/${this.medicalRecordId}/prescriptions/${this.bundleId}`, data)
        : this.$http
          .post(`/medical-records/${this.medicalRecordId}/prescriptions`, data);

      return request
        .then(({ data: result }) => {
          this.$emit('save', result);
        })
        .catch(this.$toast.error);
    },
    close() {
      this.$emit('close');
    },
    removeExpense(idx) {
      this.form.items.splice(idx, 1);
    },
    findExpense(search) {
      const params = {
        search,
        limit: 200,
        types: 'procedure',
      };

      return this.$http.get('/tuss', { params })
        .then(({ data }) => data.items);
    },
    loadMostRequested() {
      const params = {
        type: this.type,
      };

      return this.$http.get('/professional-most-requested', { params })
        .then(({ data }) => {
          this.modalMostRequested.items = data.items.map((item) => {
            item.selected = false;
            return item;
          });
        }).catch(this.$toast.error);
    },
    setExpense(data) {
      this.expense = null;
      const found = this.form.items.find(item => item.id === data.id);
      if (found) {
        const result = Number(found.quantity);
        found.quantity = result + 1;
      } else {
        this.form.items.push({
          id: '',
          key: randomHash(),
          item: {
            id: data.id,
            code: data.code,
            name: data.name,
          },
          quantity: 1,
        });
      }
    },
    blankForm() {
      return {
        bundleId: '',
        date: '',
        indication: '',
        justification: '',
        items: [],
      };
    },
  },
};
</script>

<style lang="scss">
  @import "../../../../../assets/scss/variables";
  #modal-exam {
    .group-title {
      background-color: $gray-color-light;
      margin-bottom: $layout-spacing-sm;
      padding: $layout-spacing;
    }
    .empty {
      padding: $layout-spacing-lg;
      .empty-icon {
        margin-bottom: $layout-spacing-sm;
      }
      .empty-subtitle {
        margin: 0;
      }
    }
    .table {
      background-color: $gray-color-ultra-light;
      tr th {
        background-color: $gray-color-light;
      }
    }
    .search-filter {
      margin-top: 1.4rem;
    }
  }
</style>
