<template>
  <div class="card-recipe-page">
    <div class="card-recipe-header">
      <div class="columns">
        <div class="column col-2 col-md-3 col-sm-12"
             :class="{'has-error': $v.data.date.$error}">
          <dx-input-date class="text-center"
                         v-model="data.date"
                         @blur="$v.data.date.$touch()"
                         :disabled="!printDate"
                         @change="getDate"
          />
        </div>
        <div class="column hide-sm">
          <label class="form-checkbox">
            <input type="checkbox" v-model="printDate">
            <i class="form-icon"></i>Imprimir data
          </label>
        </div>
        <div class="column col-auto col-md-auto col-sm-12">
          <div class="input-group" :class="{loading: loadingModel}">
            <select class="form-select" v-model="modelId"
                    :disabled="loadingModel"
                    @change="getModel">
              <option value="">Meus modelos de receituários</option>
              <option v-for="(item, i) in models" :value="item.id" :key="i">
                {{ item.name }}
              </option>
            </select>
            <button class="btn btn-neutral input-group-btn"
                    @click="openEditModel">
              Gerenciar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-recipe-body">
      <div style="max-width: 600px">
        <dx-autocomplete
          id="find-drugs"
          ref="find-drugs"
          v-model="drug"
          :source="findDrug"
          label="drug"
          track-by="drug-id"
          @select="setDrug"
          :debounce="800"
          placeholder="Pesquisar e adicionar medicamento"
          v-focus
        >
          <button
            slot="action"
            class="btn btn-neutral btn-action input-group-btn btn-icon"
          ><fa-icon :icon="['fal', 'search']"/></button>
          <template v-slot="{ item }">
            <template v-if="item.id">
              <strong class="text-primary">
                <fa-icon :icon="['fal', 'capsules']" />
                {{ item.name }}
              </strong>
              <small v-if="item.caption">{{ item.caption }}</small>
              <br v-if="item.description">
              <small v-if="item.description">{{ item.description }}</small>
            </template>
            <span v-else>
              <fa-icon class="text-success" :icon="['fal', 'plus-circle']" />
              Adicionar: <strong class="text-primary">{{ item.name.toUpperCase() }}</strong>
            </span>
          </template>
        </dx-autocomplete>
      </div>
      <div class="divider" />
      <div class="empty mb-2" v-if="data.drugs.length === 0">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
        </div>
        <p class="empty-title h5">Lista de medicamentos</p>
        <p class="empty-subtitle">
          Nenhum medicamento adicionado. Utilize o campo acima para realizar a busca.
        </p>
      </div>
      <item
        :data="item" v-for="(item, i) in data.drugs"
        :key="item.key"
        :dosages="dosages"
        @remove="removeDrug(i)"
        @removeDosage="removeDosage"
        v-else
      />
      <div v-if="data.drugs.length > 0">
        <textarea id="notes" rows="5" class="form-input" v-model="data.notes"
                  placeholder="Outras informações..."/>
      </div>
    </div>
    <div class="card-recipe-footer">
      <div class="d-flex centered">
        <button class="btn btn-primary btn-icon btn-icon-left"
                :disabled="!haveDrugs || pending"
                @click="openPrintModal">
          <fa-icon :icon="['fal', 'print']"/>
          <span v-if="pending">Salvando...</span>
          <span v-else>Gerar receita</span>
        </button>
        <button class="btn btn-icon-left btn-error ml-2"
                :disabled="pending"
                @click="remove">
          <fa-icon :icon="['fal', 'trash']"/>
          Excluir
        </button>
      </div>
    </div>
    <modal-edit
      v-if="editModels.show"
      :show="editModels.show"
      @close="closeEditModel"
    />
    <dx-modal title="Emitir Receita" size="md"
              v-model="printModal.show"
              v-if="printModal.show"
              id="modal-print-recipe">
      <div class="print-group">
        <h6 class="print-title">
          Assinatura digital <small class="text-warning">(Em breve)</small>
        </h6>
        <div class="print-body">
          <div class="print-image">
            <fa-icon :icon="['fal', 'signature']" />
          </div>
          <div>
            <div class="text-bold">Minha assinatura digital</div>
            <div class="text-small">
              Utilize suas credenciais para assinar digitalmente
            </div>
          </div>
          <div>
            <div class="float-right">
              <label class="form-switch">
                <input type="checkbox" disabled
                       v-model="printModal.data.signature.show">
                <i class="form-icon"></i>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="print-group">
        <h6 class="print-title">
          Enviar para o paciente <small class="text-warning">(Em breve)</small>
        </h6>
        <div class="print-body">
          <div class="print-image">
            <fa-icon :icon="['fal', 'comment-lines']" />
          </div>
          <div>
            <div class="text-bold">Enviar por WhatsApp</div>
            <div class="text-small">
              Informe o número do telefone
            </div>
            <div style="max-width: 200px">
              <input type="text" class="form-input"
                     v-model="printModal.data.sendWhatsApp.cellphone"
                     :disabled="!printModal.data.sendWhatsApp.show"
                     placeholder="(00) 0 0000-0000"
                     v-mask-phone.br>
            </div>
          </div>
          <div>
            <div class="float-right">
              <label class="form-switch">
                <input type="checkbox" disabled
                       v-model="printModal.data.sendWhatsApp.show">
                <i class="form-icon"></i>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="print-group">
        <h6 class="print-title">Receituário(s)</h6>
        <div class="print-body mb-1">
          <div class="print-image">
            <fa-icon :icon="['fal', 'file']" />
          </div>
          <div>
            <div class="text-bold">
              Receituário simples
            </div>
            <div class="columns">
              <div class="column col-auto">
                <label class="form-label text-small">Mostrar data</label>
                <select class="form-select"
                        v-model="printModal.data.simpleRecipe.showDate"
                        :disabled="!printModal.data.simpleRecipe.show">
                  <option value="true">Sim</option>
                  <option value="false">Não</option>
                </select>
              </div>
              <div class="column col-auto">
                <label class="form-label text-small">Vias</label>
                <select class="form-select"
                        v-model="printModal.data.simpleRecipe.quantity"
                        :disabled="!printModal.data.simpleRecipe.show">
                  <option value="1">1 via</option>
                  <option value="2">2 vias</option>
                  <option value="3">3 vias</option>
                  <option value="4">4 vias</option>
                </select>
              </div>
            </div>
          </div>
          <div>
            <div class="float-right">
              <label class="form-switch">
                <input type="checkbox"
                       v-model="printModal.data.simpleRecipe.show">
                <i class="form-icon"></i>
              </label>
            </div>
          </div>
        </div>
        <div class="print-divider" />
        <div class="print-body">
          <div class="print-image">
            <fa-icon :icon="['fal', 'file-invoice']" />
          </div>
          <div>
            <div class="text-bold">Receituário de controle especial</div>
            <div class="columns">
              <div class="column col-auto">
                <label class="form-label text-small">Mostrar data</label>
                <select class="form-select"
                        v-model="printModal.data.specialRecipe.showDate"
                        :disabled="!printModal.data.specialRecipe.show">
                  <option value="true">Sim</option>
                  <option value="false">Não</option>
                </select>
              </div>
              <div class="column col-auto">
                <label class="form-label text-small">Vias</label>
                <select class="form-select"
                        v-model="printModal.data.specialRecipe.quantity"
                        :disabled="!printModal.data.specialRecipe.show">
                  <option value="1">1 via</option>
                  <option value="2">2 vias</option>
                  <option value="3">3 vias</option>
                  <option value="4">4 vias</option>
                </select>
              </div>
            </div>
            <div v-for="medicine in specialControlMedicines" :key="medicine.key">
              <small>{{ medicine.name }}</small>
            </div>
          </div>
          <div>
            <div class="float-right">
              <label class="form-switch">
                <input type="checkbox"
                       v-model="printModal.data.specialRecipe.show"
                       :disabled="!hasSpecialControl">
                <i class="form-icon"></i>
              </label>
            </div>
          </div>
        </div>
      </div>
      <template slot="footer">
        <div class="d-flex float-right">
          <st-button-report
            type="pdf"
            button-class="btn btn-primary"
            :params="printParams"
            :route="printLink()"
            :disabled="!printModal.data.specialRecipe.show
             && !printModal.data.simpleRecipe.show"
          ></st-button-report>
          <button class="btn ml-1" @click="printModal.show = false">Sair</button>
        </div>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import { date } from 'src/data/validators';
import { randomHash } from '@/helpers';
import { mapState } from 'vuex';
import moment from 'moment';
import formMixin from 'src/mixins/form';
import Item from './Item.vue';
import ModalEdit from '../../../../template/components/recipe/modals/Main.vue';

export default {
  mixins: [formMixin],
  components: {
    Item,
    ModalEdit,
  },
  props: {
    medicalRecordId: {
      type: String,
    },
    dosages: {
      type: Array,
    },
    data: {
      key: {
        type: String,
      },
      id: {
        type: String,
      },
      date: {
        type: Date,
      },
      notes: {
        type: String,
      },
      drugs: {
        type: Array,
      },
    },
  },
  data() {
    return {
      drug: null,
      models: [],
      modelId: '',
      printDate: true,
      loadingModel: false,
      pending: false,
      printing: false,
      savingModel: false,
      debounceTimeout: null,
      editModels: {
        show: false,
      },
      printModal: {
        show: false,
        printing: false,
        data: this.blankPrintData(),
      },
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    haveDrugs() {
      return this.data.drugs.length > 0;
    },
    specialControlMedicines() {
      return this.data.drugs.filter(({ specialControl }) => specialControl);
    },
    hasSimpleControl() {
      return this.data.drugs.some(({ specialControl }) => !specialControl);
    },
    hasSpecialControl() {
      return this.specialControlMedicines.length > 0;
    },
    printParams() {
      const params = {};
      if (this.printModal.data.simpleRecipe.show) {
        params.simpleRecipe = true;
        params.simpleDate = this.printModal.data.simpleRecipe.showDate;
        params.simpleQuantity = this.printModal.data.simpleRecipe.quantity;
      }
      if (this.printModal.data.specialRecipe.show) {
        params.specialRecipe = true;
        params.specialDate = this.printModal.data.specialRecipe.showDate;
        params.specialQuantity = this.printModal.data.specialRecipe.quantity;
      }
      return params;
    },
  },
  validations() {
    return {
      data: {
        date: { date },
      },
    };
  },
  watch: {
    data: {
      handler() {
        this.pending = true;
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
          this.save();
        }, 1000);
      },
      deep: true,
    },
    printDate: function printDate() {
      this.data.date = this.printDate ? moment().format('YYYY-MM-DD') : '';
    },
  },
  mounted() {
    this.loadModels();
    this.printDate = !!this.data.date;
  },
  methods: {
    getDate() {
      if (!this.data.date || !moment(this.data.date).isValid()) {
        this.data.date = moment().format('YYYY-MM-DD');
      }
    },
    loadModels() {
      const params = {
        limit: 0,
        professionalId: this.user.id,
        manipulated: false,
      };
      this.$http.get('/recipe-templates', { params })
        .then(({ data }) => {
          this.models = data.items;
        })
        .catch(this.$toast.error);
    },
    getModel() {
      this.loadingModel = true;
      this.$http.get(`/recipe-templates/${this.modelId}`)
        .then(({ data }) => {
          if (data.notes) {
            this.data.notes = data.notes;
          }
          data.drugs.forEach((drug) => {
            this.data.drugs.push({
              key: randomHash(),
              id: drug.id,
              name: drug.name,
              quantity: drug.quantity,
              unit: drug.unit,
              access: drug.access,
              dosage: drug.dosage,
              specialControl: drug.specialControl || false,
            });
          });
        })
        .catch(this.$toast.error)
        .then(() => {
          this.modelId = '';
          this.loadingModel = false;
        });
    },
    openEditModel() {
      this.editModels.show = true;
    },
    closeEditModel() {
      this.loadModels();
      this.editModels.show = false;
    },
    save() {
      const data = this.clone(this.data);
      data.printDate = this.printDate;

      if (data.date && data.date.length < 10) {
        data.date = '';
      }

      const request = !this.data.id
        ? this.$http
          .post(`/medical-records/${this.medicalRecordId}/recipes`, data)
        : this.$http
          .put(`/medical-records/${this.medicalRecordId}/recipes/${this.data.id}`, data);

      return request
        .then(({ data: result }) => {
          this.data.id = result.id;
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro ao salvar este registro. Tente novamente!');
        })
        .finally(() => {
          this.pending = false;
        });
    },
    remove() {
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente excluir este receituário?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.$emit('remove');
              close();
            },
          },
        ],
      });
    },
    removeDrug(idx) {
      this.data.drugs.splice(idx, 1);
    },
    removeDosage({ id, idx }) {
      this.$emit('removeDosage', { id, idx });
    },
    async findDrug(search) {
      const params = {
        search,
        limit: 100,
      };

      const items = await this.$http.get('/medicines', { params })
        .then(({ data }) => data.items);

      items.unshift({
        id: null,
        name: search,
      });

      return items;
    },
    setDrug(data) {
      this.drug = null;
      this.data.drugs.push({
        key: randomHash(),
        id: data.id,
        name: `${data.name} ${data.caption || ''}`,
        quantity: 1,
        unit: data.unit || 'packing',
        access: data.access || 'oral',
        dosage: '',
        specialControl: data.specialControl || false,
      });
    },
    saveModel() {
      const drugs = this.data.drugs
        .filter(item => (
          item.name && item.quantity
        ));

      if (!drugs) {
        return;
      }

      this.savingModel = true;

      const names = drugs
        .map(drug => drug.name.split(' ')[0]);

      const data = {
        id: this.modelId,
        name: names.join(' + '),
        professionals: [
          { id: this.user.id, name: this.user.name },
        ],
        active: true,
        drugs,
      };

      const request = !data.id
        ? this.$http.post('/recipe-templates', data)
        : this.$http.put(`/recipe-templates/${data.id}`, data);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data: result }) => {
          const found = this.models.find(model => model.id === result.id);
          if (!found) {
            this.models.push({
              id: result.id,
              name: result.name,
              drugs: result.drugs.length,
            });
          } else {
            found.drugs = result.drugs.length;
          }
          this.modelId = result.id;
          this.$toast.show('Salvo com sucesso!');
        })
        .catch(this.$toast.error)
        .then(() => {
          this.savingModel = false;
        });
    },
    printLink() {
      return `/medical-records/${this.medicalRecordId}/recipes/${this.data.id}/print`;
    },
    openPrintModal() {
      if (this.pending) {
        return;
      }
      this.printModal.data = this.blankPrintData();
      this.printModal.data.simpleRecipe.showDate = this.printDate;
      this.printModal.data.specialRecipe.showDate = this.printDate;
      this.printModal.data.simpleRecipe.show = this.hasSimpleControl;
      this.printModal.data.specialRecipe.show = this.hasSpecialControl;
      this.printModal.show = true;
    },
    blankPrintData() {
      return {
        signature: {
          show: false,
        },
        sendWhatsApp: {
          show: false,
          cellphone: '',
        },
        simpleRecipe: {
          show: true,
          showDate: true,
          quantity: 1,
        },
        specialRecipe: {
          show: false,
          showDate: false,
          quantity: 2,
        },
      };
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/scss/variables';
.card-recipe-page {
    .divider {
      border-top: $border-width * 2 solid $light-color;
      margin: 1rem 0;
    }
    background-color: lighten($gray-color, 24%);
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    margin-bottom: $layout-spacing-lg;
    .card-recipe-header {
      background-color: lighten($gray-color, 15%);
      padding: $layout-spacing;
    }
    .card-recipe-body {
      padding: $layout-spacing-lg $layout-spacing;
    }
    .card-recipe-footer {
      padding: $layout-spacing-lg;
      text-align: center;
      button {
        min-width: 7rem;
      }
    }
  }

#modal-print-recipe {
  .print-group {
    margin: $layout-spacing-lg 0;
    .print-title {
      color: $secondary-color;
      border-bottom: $border-width solid $border-color;
      padding-bottom: $layout-spacing-sm;
    }
    .print-divider {
      border-bottom: $border-width dashed $border-color;
    }
    .print-body {
      display: grid;
      grid-template-columns: 1fr 4fr 2fr;
      padding: $layout-spacing;
      .print-image {
        align-items: center;
        justify-content: center;
        display: flex;
        background: $gray-color-ultra-light;
        border-radius: 50px;
        height: 2.5rem;
        width: 2.5rem;
        svg {
          color: $info-color;
          font-size: $font-size-lg;
        }
      }
    }
  }
}

</style>
