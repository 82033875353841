<template>
  <div class="anamnesis-page">
    <div class="card-detail" v-if="expenses && expenses.length > 0">
      <div class="card-detail-title">Procedimentos agendados</div>
      <div class="card-detail-data"
           v-for="(expense, i) in expenses" :key="i">
        {{ expense.code | tuss }} - {{ expense.name }}
      </div>
    </div>
    <div class="btn-group">
      <button class="btn"
              :class="item.id === form.anamnesis.templateId
                ? 'btn-primary'
                : 'btn-neutral'"
              @click="openTemplate(item.id)"
              v-for="(item, i) in templates" :key="i">
        {{ item.name }}
      </button>
    </div>

    <div class="divider" />

    <div v-if="ready">
      <component
        v-if="hasCustomRenderer"
        :is="customComponent"
        :data="form.anamnesis"
        :ophthalmology="ophthalmology"
        :id="data.id"
        @change="customFormChange"
      />
      <template v-else>
        <div class="hover-link text-bold form-group"
             :class="anthropometry.show ? 'text-warning' : 'text-primary'"
             @click="anthropometry.show = !anthropometry.show">
          <fa-icon :icon="['fal', anthropometry.show ? 'chevron-up' : 'chevron-down']"/>
          Antropometria e sinais vitais
        </div>

        <template v-if="anthropometry.show">
          <div class="columns">
            <div class="column col-3 col-md-6 col-sm-12 form-group">
              <label>Peso</label>
              <div class="input-group">
                <dx-input-number id="weight" class="form-input"
                                 v-model="form.anthropometry.weight"
                                 :precision="3" maxlength="7" />
                <span class="input-group-addon text-gray">kg</span>
              </div>
            </div>
            <div class="column col-3 col-md-6 col-sm-12 form-group">
              <label>Altura</label>
              <div class="input-group">
                <dx-input-number id="height" class="form-input"
                                 v-model="form.anthropometry.height"
                                 :precision="1" maxlength="5" />
                <span class="input-group-addon text-gray">cm</span>
              </div>
            </div>
            <div class="column col-2 col-md-6 col-sm-12 form-group">
              <label>IMC</label>
              <div class="input-group">
              <span class="form-input text-right text-bold bg-gray">
                {{ imc | number(2) }}
              </span>
                <span class="input-group-addon text-info tooltip c-hand"
                      data-tooltip="Curvas de crescimento" @click="openGrowthChart">
                <fa-icon :icon="['fal', 'chart-line']" />
              </span>
              </div>
            </div>
            <div class="column col-2 col-md-6 col-sm-12 form-group">
              <label>Per. cefálico</label>
              <div class="input-group">
                <dx-input-number id="cephalic-perimeter" class="form-input"
                                 v-model="form.anthropometry.cephalicPerimeter"
                                 :precision="1" maxlength="5" />
                <span class="input-group-addon text-gray">cm</span>
              </div>
            </div>
            <div class="column col-2 col-md-6 col-sm-12 form-group">
              <label>C. abdominal</label>
              <div class="input-group">
                <dx-input-number id="abdominal-circumference" class="form-input"
                                 v-model="form.anthropometry.abdominalCircumference"
                                 :precision="1" maxlength="5" />
                <span class="input-group-addon text-gray">cm</span>
              </div>
            </div>
            <div class="column col-3 col-md-6 col-sm-12">
              <label for="blood-pressure">Pressão Arterial</label>
              <div class="input-group">
                <input type="text" id="blood-pressure" class="form-input text-right"
                       v-model="form.vitalSigns.bloodPressure" maxlength="7">
                <span class="input-group-addon text-gray">mm/Hg</span>
              </div>
            </div>
            <div class="column col-3 col-md-6 col-sm-12">
              <label>F. Respiratória</label>
              <div class="input-group">
                <dx-input-number id="respiratory-frequency" class="form-input"
                                 v-model="form.vitalSigns.respiratoryFrequency"
                                 :precision="0" maxlength="3" />
                <span class="input-group-addon text-gray">mpm</span>
              </div>
            </div>
            <div class="column col-2 col-md-6 col-sm-12">
              <label>F. Cardíaca</label>
              <div class="input-group">
                <dx-input-number id="heart-rate" class="form-input"
                                 v-model="form.vitalSigns.heartRate"
                                 :precision="0" maxlength="3" />
                <span class="input-group-addon text-gray">bpm</span>
              </div>
            </div>
            <div class="column col-2 col-md-6 col-sm-12">
              <label>Temperatura</label>
              <div class="input-group">
                <dx-input-number id="temperature" class="form-input"
                                 v-model="form.vitalSigns.temperature"
                                 :precision="2" maxlength="5" />
                <span class="input-group-addon text-gray">ºC</span>
              </div>
            </div>
            <div class="column col-2 col-md-6 col-sm-12">
              <label>Saturação O&sup2;</label>
              <div class="input-group">
                <dx-input-number id="saturation" class="form-input"
                                 v-model="form.vitalSigns.saturation"
                                 :precision="2" maxlength="5" />
                <span class="input-group-addon text-gray">%</span>
              </div>
            </div>
          </div>
          <div class="divider" data-content="" />
        </template>

        <div class="columns">
          <div class="column col-6 col-sm-12 form-group mb-2">
            <label>CID</label>
            <dx-autocomplete
              id="cid"
              v-model="cid"
              :source="findCid"
              @select="setCid"
              :debounce="800"
              placeholder="Pesquisar CID">
              <button slot="action"
                      class="btn btn-neutral btn-action input-group-btn btn-icon">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
              <template v-slot="{ item }">
                <fa-icon class="text-primary" :icon="['fal', 'heartbeat']" />
                {{ item.code }} - {{ item.name }}
              </template>
            </dx-autocomplete>
          </div>
        </div>
        <div class="chip bg-secondary mb-2" v-for="(cid, c) in form.cids" :key="cid.code">
          {{ cid.code }} - {{ cid.name.substring(0, 40) }}{{ cid.name.length > 40 ? '...' : '' }}
          <fa-icon class="c-hand ml-2" :icon="['fal', 'times']"
                   @click="removeCid(c)"/>
        </div>

        <div class="input-group form-group">
          <select class="form-select" v-model="modelId"
                  @change="getModel" :disabled="loading">
            <option value="">[Meus modelos]</option>
            <option v-for="(item, i) in models" :value="item.id" :key="i">
              {{ item.name }}
            </option>
          </select>
          <button class="btn btn-neutral input-group-btn"
                  @click="openEditModel">
            Gerenciar
          </button>
        </div>
        <div v-for="(input, n) in form.anamnesis.inputs" :key="input.id + n">
          <question
            class="mb-2"
            v-model="input.model"
            :label="input.label"
            :type="input.type"
            :options="input.options"
            :params="input.params"
          />
        </div>
      </template>
    </div>
    <div class="anamnesis-page-footer">
      <button class="btn btn-gray btn-icon-left"
              :class="{loading: printing}"
              @click="print" :disabled="printing || pending">
        <fa-icon :icon="['fal', 'print']"/>
        <span v-if="pending">Salvando...</span>
        <span v-else>Imprimir documento</span>
      </button>
    </div>
    <modal-edit
      v-if="editModels.show"
      :show="editModels.show"
      @close="closeEditModel"
    />
    <modal-growth
      v-if="growthCurves.show"
      :patient-id="patient.id"
      :show="growthCurves.show"
      @close="closeGrowthModel"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import formMixin from 'src/mixins/form';
import moment from 'moment';
import DateOld from '@/filters/date-old';
import Cpf from '@/filters/cpf';
import Phone from '@/filters/phone';
import localforage from 'localforage';
import Question from '../Question.vue';
import ModalEdit from '../../../template/components/anamnesis/modals/Main.vue';
import ModalGrowth from '../../../patient/modals/GrowthCurves.vue';
import OphthalmologyForm from './OphthalmologyForm.vue';

export default {
  components: {
    ModalEdit,
    Question,
    ModalGrowth,
    OphthalmologyForm,
  },
  props: {
    ophthalmology: {
      type: Object,
    },
    expenses: {
      type: Array,
    },
    patient: {
      id: {
        type: String,
      },
      name: {
        type: String,
      },
      birthDate: {
        type: Date,
      },
      gender: {
        type: String,
      },
      phone: {
        type: String,
      },
      cellphone: {
        type: String,
      },
      identity: {
        type: {
          type: String,
        },
        value: {
          type: String,
        },
      },
      addresses: {
        type: Array,
      },
      allergy: {
        type: Boolean,
      },
    },
    data: {
      id: {
        type: String,
      },
      anthropometry: {
        height: {
          type: Number,
        },
        weight: {
          type: Number,
        },
        cephalicPerimeter: {
          type: Number,
        },
        abdominalCircumference: {
          type: Number,
        },
      },
      vitalSigns: {
        bloodPressure: {
          type: String,
        },
        respiratoryFrequency: {
          type: Number,
        },
        heartRate: {
          type: Number,
        },
        temperature: {
          type: Number,
        },
        saturation: {
          type: Number,
        },
      },
      anamnesis: {
        templateId: {
          type: String,
        },
        inputs: {
          type: Array,
        },
      },
      cids: {
        type: Array,
      },
    },
    pending: {
      type: Boolean,
    },
  },
  mixins: [
    formMixin,
  ],
  data() {
    return {
      form: this.data,
      templates: [],
      models: [],
      modelId: '',
      loading: false,
      printing: false,
      cid: null,
      editModels: {
        show: false,
      },
      growthCurves: {
        show: false,
      },
      anthropometry: {
        show: false,
      },
      templateRenderer: null,
      ready: false,
    };
  },
  async mounted() {
    await this.loadTemplates();
    await this.loadModels();
    if (!this.form.anamnesis.templateId) {
      const templateId = await this.getProfessionalSettings();
      if (templateId) {
        this.form.anamnesis.templateId = templateId;
      } else {
        this.form.anamnesis.templateId = this.templates.length > 0 ? this.templates[0].id : '';
      }
      this.changeType(false);
    } else {
      this.changeType(true);
    }
    this.ready = true;
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    imc() {
      const height = this.decimalToApi(this.form.anthropometry.height);
      const weight = this.decimalToApi(this.form.anthropometry.weight);
      const result = (weight / (height * height)) * 10000;
      return result < 14 || result > 55 ? 0 : result;
    },
    hasCustomRenderer() {
      return this.templateRenderer !== null;
    },
    customComponent() {
      return this.hasCustomRenderer ? `${this.templateRenderer}-form` : 'div';
    },
  },
  methods: {
    customFormChange({ id, value }) {
      const input = this.form.anamnesis.inputs.find(item => item.id === id);
      if (input) {
        input.model.value = value;
      }
    },
    openTemplate(templateId) {
      if (this.form.anamnesis
        && this.form.anamnesis.inputs
        && this.form.anamnesis.inputs.length > 0
        && this.form.anamnesis.inputs[0].model
        && this.form.anamnesis.inputs[0].model.value) {
        this.$dialog.show('', {
          html:
            '<div class="text-center">'
            + '<h5 class="text-center">Atenção!</h5>'
            + '<div>Ao trocar o formato de atendimento, os dados informados</div>'
            + '<div>serão perdidos. Deseja continuar?</div>'
            + '</div>',
          buttons: [
            {
              label: 'Não',
              classes: '',
            }, {
              label: 'Sim',
              classes: 'btn-primary btn-error ml-2',
              click: (close) => {
                this.form.anamnesis.templateId = templateId;
                this.changeType(false);
                close();
              },
            },
          ],
        });
      } else {
        this.form.anamnesis.templateId = templateId;
        this.changeType(false);
      }
    },
    changeType(firstLoad) {
      if (this.form.anamnesis.templateId) {
        this.setProfessionalSettings(this.form.anamnesis.templateId);
        const template = this.templates
          .find(item => item.id === this.form.anamnesis.templateId);
        this.templateRenderer = template.templateRenderer || null;
        this.form.anamnesis.templateRenderer = this.templateRenderer;
        if (!firstLoad || template.id !== this.form.anamnesis.templateId) {
          this.form.anamnesis.inputs = this
            .setTemplate(this.form.anamnesis.templateId, template.inputs);
        }
      }
    },
    setProfessionalSettings(templateId) {
      localforage.setItem(`${this.user.id}:anamnesis_types`, templateId);
    },
    getProfessionalSettings() {
      return localforage.getItem(`${this.user.id}:anamnesis_types`);
    },
    setTemplate(id, items) {
      return items.map(item => ({
        ...item,
        model: {
          value: item.value || null,
          observation: item.observation || '',
        },
      }));
    },
    loadTemplates() {
      const params = {
        limit: 0,
        active: true,
        type: 'anamnesis',
      };
      return this.$http.get('/templates', { params })
        .then(({ data }) => {
          this.templates = data.items;
        });
    },
    loadModels() {
      const params = {
        limit: 0,
        professionalId: this.user.id,
      };
      this.$http.get('/anamnesis-templates', { params })
        .then(({ data }) => {
          this.models = data.items;
        })
        .catch(this.$toast.error);
    },
    getModel() {
      if (!this.modelId) {
        return;
      }

      if (this.form.anamnesis
        && this.form.anamnesis.inputs
        && this.form.anamnesis.inputs.length > 0
        && this.form.anamnesis.inputs[0].model
        && this.form.anamnesis.inputs[0].model.value) {
        this.$dialog.show('', {
          html:
            '<div class="text-center">'
            + '<h5 class="text-center">Atenção!</h5>'
            + '<div>O modelo selecionado irá substituir as informações</div>'
            + '<div>digitadas. Deseja continuar?</div>'
            + '</div>',
          buttons: [
            {
              label: 'Não',
              classes: '',
              click: (close) => {
                this.modelId = '';
                close();
              },
            }, {
              label: 'Sim',
              classes: 'btn-primary btn-error ml-2',
              click: (close) => {
                this.setAnamnesis();
                close();
              },
            },
          ],
        });
      } else {
        this.setAnamnesis();
      }
    },
    setAnamnesis() {
      this.loading = true;
      this.$http.get(`/anamnesis-templates/${this.modelId}`)
        .then(({ data }) => {
          if (data && data.data) {
            const address = this.patient.addresses
            && this.patient.addresses.length > 0
              ? this.patient.addresses[0] : null;

            if (this.form.anamnesis
              && this.form.anamnesis.inputs
              && this.form.anamnesis.inputs.length > 0
              && this.form.anamnesis.inputs[0].model) {
              this.form.anamnesis.inputs[0].model.value = data.data
                .replace(/\[date]/g, moment().format('DD/MM/YYYY'))
                .replace(/\[hour]/g, moment().format('HH:mm'))
                .replace(/\[cid]/g, '')
                .replace(/\[patient_name]/g, this.patient.name)
                .replace(/\[patient_gender]/g,
                  this.patient.gender === 'male'
                    ? 'Masculino'
                    : 'feminino')
                .replace(/\[patient_birth_date]/g, moment(this.patient.birthDate)
                  .format('DD/MM/YYYY'))
                .replace(/\[patient_age]/g,
                  DateOld(this.patient.birthDate, true))
                .replace(/\[patient_cpf]/g,
                  this.patient.identity && this.patient.identity.value
                    ? Cpf(this.patient.identity.value)
                    : null)
                .replace(/\[patient_phone]/g, Phone(this.patient.cellphone))
                .replace(/\[patient_address]/g, this.formatAddress(address));
            }
          }
        })
        .catch(this.$toast.error)
        .then(() => {
          this.loading = false;
          this.modelId = '';
        });
    },
    openEditModel() {
      this.editModels.show = true;
    },
    closeEditModel() {
      this.loadModels();
      this.editModels.show = false;
    },
    closeGrowthModel() {
      this.growthCurves.show = false;
    },
    openGrowthChart() {
      this.growthCurves.show = true;
    },
    formatAddress(item) {
      if (item) {
        return `${
          item.addressLine1
        } ${
          item.addressLine2
        }, ${
          item.neighborhood
        } - ${
          item.city
        }-${
          item.state
        }`;
      }
      return '';
    },
    removeCid(idx) {
      this.form.cids.splice(idx, 1);
    },
    findCid(search) {
      return this.$http.get(`/services/cids?search=${search}`)
        .then(({ data }) => {
          if (data.items.length === 0) {
            return [];
          }
          return data.items;
        });
    },
    setCid(data) {
      this.cid = null;
      this.form.cids.push({
        code: data.code,
        name: data.name,
      });
    },
    print() {
      this.printing = true;
      return this.$file
        .print(`/medical-records/${this.form.id}/print`)
        .catch(this.$toast.error)
        .finally(() => {
          this.printing = false;
        });
    },
  },
};
</script>

<style lang="scss">
  @import "../../../../assets/scss/variables";
  .anamnesis-page {
    .card-detail {
      background-color: lighten($gray-color-light, 5%);
      border: $border-color solid $border-width;
      border-radius: $border-radius;
      margin-bottom: $layout-spacing;
      padding: $layout-spacing;
      .card-detail-title {
        font-weight: bold;
        margin-bottom: $layout-spacing;
      }
    }
    .document-editor-body {
      .ck.ck-content.ck-editor__editable {
        min-height: 15rem;
      }
    }
    .anamnesis-page-footer {
      padding: $layout-spacing-lg;
      text-align: center;
      button {
        min-width: 7rem;
      }
    }
  }
</style>
